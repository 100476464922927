import AppLogin from '@/views/AppLogin.vue'
import Bank from '@/views/Bank.vue'
import Faqs from '@/views/Faqs.vue'
import CustomerProfile from '@/views/CustomerProfile.vue'
import HomePage from '@/views/HomePage.vue'
import Login from '@/views/Login.vue'
import Lists from '@/views/Lists.vue'
import Configuration from '@/views/Configuration.vue'
import ListDetail from '@/views/ListDetail.vue'
import Logout from '@/views/Logout.vue'
import Search from '@/views/Search.vue'
import ChatSearch from '@/views/ChatSearch.vue'
import Chat from '@/views/Chat.vue'
import Contact from '@/views/Contact.vue'
import Media from '@/views/Media.vue'
import NewMediaStep1 from '@/views/NewMediaStep1.vue'
import NewMediaStep2 from '@/views/NewMediaStep2.vue'
import PrePayment from '@/views/PrePayment.vue'
import PrePurchase from '@/views/PrePurchase.vue'
import Profile from '@/views/Profile.vue'
import Subscriptions from '@/views/Subscriptions.vue'
import Notifications from '@/views/Notifications.vue'
import UserTypeSelector from '@/views/UserTypeSelector.vue'
import { RouteRecordRaw } from 'vue-router'
import { createRouter, createWebHistory } from '@ionic/vue-router'
import { userGuard as beforeEnter } from '@/router/guards.js'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    beforeEnter,
  },
  // public profile
  {
    path: "/customer-profile/:public_key",
    name: "CustomerProfile",
    props: true,
    component: CustomerProfile,
    beforeEnter,
  },
  {
    path: "/media/:public_key",
    name: "Media",
    props: true,
    component: Media,
    beforeEnter,
  },
  {
    path: "/chat-search",
    name: "ChatSearch",
    component: ChatSearch,
    beforeEnter,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    beforeEnter,
  },
  {
    path: "/chat/:public_key",
    name: "Chat",
    props: true,
    component: Chat,
    beforeEnter,
  },
  {
    path: "/faqs",
    name: "Faqs",
    component: Faqs,
    beforeEnter,
  },
  {
    path: "/configuration",
    name: "Configuration",
    component: Configuration,
    beforeEnter,
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications,
    beforeEnter,
  },
  {
    path: "/search",
    name: "Search",
    component: Search,
    beforeEnter,
  },
  {
    path: "/lists",
    name: "Lists",
    component: Lists,
    beforeEnter,
  },
  {
    path: "/lists/:type",
    props: true,
    name: "ListDetail",
    component: ListDetail,
    beforeEnter,
  },
  {
    path: "/subscriptions",
    name: "Subscriptions",
    component: Subscriptions,
    beforeEnter,
  },
  {
    path: "/new-media/step1/:seed",
    name: "NewMediaStep1",
    props: true,
    component: NewMediaStep1,
    beforeEnter,
  },
  {
    path: "/new-media/step2/:public_key",
    name: "NewMediaStep2",
    props: true,
    component: NewMediaStep2,
    beforeEnter,
  },
  {
    path: '/profile/:public_key',
    props: true,
    name: 'Profile',
    component: Profile,
    beforeEnter,
  },
  {
    path: '/bank',
    name: 'Bank',
    component: Bank,
    beforeEnter,
  },
  {
    path: '/pre-payment/:public_key',
    name: 'PrePayment',
    props: true,
    component: PrePayment,
    beforeEnter,
  },
  {
    path: '/pre-purchase/:public_key',
    name: 'PrePurchase',
    props: true,
    component: PrePurchase,
    beforeEnter,
  },
  {
    path: '/app-login',
    name: 'AppLogin',
    component: AppLogin,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
  },
  {
    path: '/user-type-selector',
    name: 'UserTypeSelector',
    component: UserTypeSelector,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
